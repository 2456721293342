const navigation = () => {
    const navigationMenu = document.querySelectorAll('.js-navigation-menu');
    if (navigationMenu) {
        navigationMenu.forEach((element) => {
            element.onmouseover = function () {
                const hasChildren = element.querySelectorAll('div').length > 0;
                if (!hasChildren || element.classList.contains('active')) {
                    return;
                }
                element.querySelectorAll('div').forEach((div) => {
                    if (!div.dataset.hasOwnProperty('nav') || div.dataset.nav !== 'skip') {
                        div.classList.remove('hidden');
                    }
                });
            };
            element.onclick = function (event) {
                const hasChildren = element.querySelectorAll('div').length > 0;
                if (!hasChildren) {
                    return;
                }
                if ((event.target === element || (event.target instanceof HTMLElement && event.target.parentElement === element))
                    && window.innerWidth < 800) {
                    event.preventDefault();
                    if (element.classList.contains('active')) {
                        element.querySelectorAll('div').forEach((div) => {
                            if (!div.dataset.hasOwnProperty('nav') || div.dataset.nav !== 'skip') {
                                div.classList.add('hidden');
                            }
                        });
                        element.classList.remove('active');
                    }
                    else {
                        element.querySelectorAll('div').forEach((div) => {
                            if (!div.dataset.hasOwnProperty('nav') || div.dataset.nav !== 'skip') {
                                div.classList.remove('hidden');
                            }
                        });
                        // @ts-ignore
                        element.parentElement.querySelectorAll('.active').forEach((active) => {
                            active.classList.remove('active');
                        });
                        element.classList.add('active');
                    }
                }
            };
            element.onmouseout = function () {
                const hasChildren = element.querySelectorAll('div').length > 0;
                if (!hasChildren || element.classList.contains('active')) {
                    return;
                }
                element.querySelectorAll('div').forEach((div) => {
                    if (!div.dataset.hasOwnProperty('nav') || div.dataset.nav !== 'skip') {
                        div.classList.add('hidden');
                    }
                });
            };
        });
    }
    const navigationToggle = document.querySelector('.js-navigation-toggle');
    if (navigationToggle) {
        navigationToggle.addEventListener('click', () => {
            const navElement = document.querySelector('nav');
            if (navElement === null) {
                return;
            }
            if (navElement.classList.contains('hidden')) {
                navElement.classList.remove('hidden');
                navElement.classList.add('flex');
            }
            else {
                navElement.classList.remove('flex');
                navElement.classList.add('hidden');
            }
        });
    }
    if (document.querySelector('nav')) {
        window.addEventListener('resize', () => {
            if (window.matchMedia('screen and (min-width: 640px)')) {
                const navElement = document.querySelector('nav');
                if (navElement === null) {
                    return;
                }
                if (!navElement.classList.contains('hidden')) {
                    navElement.classList.add('hidden');
                    navElement.classList.remove('flex');
                }
                document.querySelectorAll('.js-navigation-menu').forEach((element) => {
                    element.querySelectorAll('.active').forEach((active) => {
                        active.classList.remove('active');
                    });
                });
            }
        });
    }
};
export default navigation;
