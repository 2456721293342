import * as React from 'react';
import ProfileHealthScoreTile from './Partials/ProfileHealthScoreTile';
import ProfileAppScoreTile from './Partials/ProfileAppScoreTile';
import { useI18n } from '../../../Translation';
const ProfileScoreContainer = () => {
    const i18n = useI18n();
    return <>
        <div className="pb-5">
            <h3 className="mt-5 text-xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                {i18n['profile.profileScoresIntroHeading']}
            </h3>
            <p className="mt-2 max-w-4xl text-base text-gray-500">
                {i18n['profile.profileScoresIntro']}
            </p>
        </div>

        <div className="grid grid-cols-1 gap-5 sm:grid-cols-3">
            <ProfileHealthScoreTile />
            <ProfileAppScoreTile />
        </div>
    </>;
};
ProfileScoreContainer.displayName = 'ProfileScoreContainer';
export default ProfileScoreContainer;
