import * as React from 'react';
import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';
import HealthScoreDoughnutChart from '../../Charts/HealthScoreDoughnutChart';
import { HealthScoreColor } from '../../Charts/HealthScroreColorEnum';
import { myAreaStatsAppScore } from '../../../../Utilities/api';
import Spinner from '../../Spinner';
import { useI18n } from '../../../../Translation';
const ProfileAppScoreTile = () => {
    const i18n = useI18n();
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const formatDateString = (dateString) => {
        const date = new Date(dateString);
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            hourCycle: 'h23'
        };
        return date.toLocaleDateString('de-DE', options);
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await myAreaStatsAppScore();
                if (result == 404) {
                    setNotFound(true);
                }
                else {
                    setData(result);
                }
            }
            catch (error) {
                Sentry.captureException(error);
                setHasError(true);
            }
            finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, []);
    const getScoreColor = (score) => {
        if (score >= 66.6) {
            return HealthScoreColor.green;
        }
        if (score >= 33.3) {
            return HealthScoreColor.yellow;
        }
        return HealthScoreColor.red;
    };
    return <div className="overflow-hidden relative rounded-lg bg-white px-4 py-5 shadow-lg sm:p-6">
        {isLoading ? (<Spinner />) : (<>
                {hasError ? (<i className="mt-1">{i18n['profile.healthScore.errorWhileLoading']}</i>) : (<>
                        <div className="flex justify-between items-center">
                            <span className="text-xl font-semibold tracking-tight text-gray-900">Gleichgewicht</span>

                            {data ? (<span className="text-xs leading-6 text-gray-500">{i18n['profile.healthScore.resultDate'](formatDateString(data.creationDate))}</span>) : notFound ? (<></>) : (<></>)}
                        </div>

                        <div className="relative mt-5 mb-12">
                            {notFound ? (<div className="flex flex-col justify-center items-center">
                                    <HealthScoreDoughnutChart color={HealthScoreColor.green} score={'80'}/>

                                    <div className="absolute inset-0 backdrop-filter rounded-full backdrop-blur-lg flex flex-col gap-y-4 justify-center items-center">
                                        <p className="text-lg font-semibold max-w-[50%] text-center">
                                            {i18n['profile.healthScore.noTestYet']}
                                        </p>
                                        <div className="flex"></div>
                                    </div>
                                </div>) : data ? (<HealthScoreDoughnutChart color={getScoreColor(Number(data.scores.score))} score={data.scores.score}/>) : (<></>)}
                        </div>

                        <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                            <div className="text-sm flex justify-between items-center">
                                <div></div>
                                <div style={{ width: '32px' }}></div>
                            </div>
                        </div>
                    </>)}
            </>)}

    </div>;
};
ProfileAppScoreTile.displayName = 'ProfileAppScoreTile';
export default ProfileAppScoreTile;
