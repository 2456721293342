import * as React from 'react';
import { useRef, useState } from 'react';
import ReactPlayer from 'react-player';
const ResponsiveVideoPlayer = ({ playerConfig, sourceConfig, onStart, onProgress, onEnded, disableSeek, }) => {
    const [playedSeconds, setPlayedSeconds] = useState(0);
    const videoRef = useRef(null);
    const handleProgress = (state) => {
        setPlayedSeconds(state.playedSeconds);
        onProgress(state);
        if (playerConfig.hasOwnProperty('onProgress')) {
            playerConfig.onProgress();
        }
    };
    const handleDuration = (duration) => {
        setPlayedSeconds(duration);
    };
    if (sourceConfig.file?.forceVideo) {
        return <div className={'w-full react-player-wrapper'}>
            <ReactPlayer {...playerConfig} width={'100%'} height={'500px'} config={{
                file: sourceConfig.file,
            }}/>
        </div>;
    }
    const [showGdprWarning, setShowGdprWarning] = useState(true);
    return <div className={'w-full react-player-wrapper'}>
        <ReactPlayer ref={videoRef} className='react-player' {...playerConfig} onClickPreview={() => setShowGdprWarning(false)} onProgress={handleProgress} onDuration={handleDuration} onEnded={() => {
            if (playedSeconds > 1) {
                onEnded();
            }
        }} onSeek={() => {
            if (videoRef && disableSeek) {
                videoRef.current.getCurrentTime() > playedSeconds &&
                    videoRef.current.seekTo(playedSeconds);
            }
        }} onReady={onStart} width={'100%'} height={'100%'} config={{
            youtube: {
                playerVars: {
                    rel: 1,
                    showinfo: 1,
                    iv_load_policy: 1,
                    modestbranding: 1,
                    cc_load_policy: 1,
                },
            },
            ...sourceConfig
        }}/>
        {showGdprWarning ? <span>Bei Klick wird dieses Video von den YouTube-Servern geladen. Details siehe Datenschutzerklärung.</span> : ''}
    </div>;
};
ResponsiveVideoPlayer.displayName = 'ResponsiveVideoPlayer';
export default ResponsiveVideoPlayer;
