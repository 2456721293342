import * as React from 'react';
import { SupportWidgetContext } from './SupportWidget';
const SupportButton = (props) => {
    const { handleOpen, renderAsLink } = React.useContext(SupportWidgetContext);
    if (renderAsLink) {
        return <button type="button" onClick={handleOpen} className="mt-2 hover:text-brand-secondary text-uppercase duration-500 transition-colors" style={{ textTransform: 'uppercase' }}>
            {props.title || 'Kontakt'}
        </button>;
    }
    return <>
        <button type="button" onClick={handleOpen} className="inline-flex items-center gap-x-2 rounded-md bg-brand-primary px-3.5 py-2.5 text-base font-semibold text-white shadow-sm hover:bg-brand-secondary md:bottom-24 md:right-12">
            {props.title || 'Kontakt'}
        </button>
    </>;
};
SupportButton.displayName = 'SupportButton';
export default SupportButton;
