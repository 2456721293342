import { useEffect, useState } from 'react';
export const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
        // Update debounced value after delay
        const handler = setTimeout(() => setDebouncedValue(value), delay);
        // Cancel the timeout if value changes (also on delay change or unmount)
        return () => clearTimeout(handler);
    }, [JSON.stringify(value), delay]);
    return debouncedValue;
};
export function debounce(func, delay) {
    let timeoutId = null;
    return (...args) => {
        // Clear the previous timeout
        if (timeoutId !== null) {
            clearTimeout(timeoutId);
        }
        // Set a new timeout
        timeoutId = setTimeout(() => {
            func(...args);
            timeoutId = null;
        }, delay);
    };
}
