import * as React from 'react';
import { useState } from 'react';
import SupportPanel from './SupportPanel';
import SupportButton from './SupportButton';
export const SupportWidgetContext = React.createContext(null);
const SupportWidget = ({ authenticated, reasons, renderAsLink, sitzkriegerBewegtMode, title }) => {
    const [isOpen, setIsOpen] = useState(false);
    const handleOpen = () => {
        setIsOpen(!isOpen);
    };
    return <>
        <SupportWidgetContext.Provider value={{
            authenticated,
            reasons,
            isOpen,
            handleOpen,
            renderAsLink,
            sitzkriegerBewegtMode,
        }}>
            <SupportPanel></SupportPanel>
            <SupportButton title={title}></SupportButton>
        </SupportWidgetContext.Provider>
    </>;
};
SupportWidget.displayName = 'SupportWidget';
export default SupportWidget;
