import * as React from 'react';
import { useCallback, useState } from 'react';
import { getMediaElement, videoStatisticsFinish, videoStatisticsStart, videoStatisticsWatch } from '../../Utilities/api';
import ResponsiveVideoPlayer from './Players/ResponsiveVideoPlayer';
import DefaultReactPlayer from './Players/DefaultReactPlayer';
import { debounce } from '../Utils/useDebounce';
const VideoPlayer = (props) => {
    const { id, data, controls, playing, muted, preview, responsive, gaAttribute } = props;
    const [userVideoId, setUserVideoId] = useState('');
    const gaAttributes = gaAttribute?.split(' ').map(element => {
        const split = element.split('=');
        return {
            identifier: split[0],
            label: split[1],
        };
    });
    const attributes = {};
    gaAttributes?.forEach(element => {
        if (element.identifier && element.label) {
            attributes['data-' + element.identifier] = element.label;
        }
    });
    let url = '';
    let customPreview = preview;
    if (data.type === 'youtube') {
        url = `https://youtube.com/watch?v=${data.video}`;
        customPreview = true;
    }
    const config = {
        file: {},
    };
    if (data.type === 'video') {
        url = getMediaElement(data.video, 'v');
        config.file['forceVideo'] = true;
        let thumbnail = data.thumbnail;
        if (props.thumbnailWidth || props.thumbnailHeight) {
            if (!thumbnail.includes('?')) {
                thumbnail += '?';
            }
            const searchParams = {};
            if (props.thumbnailWidth) {
                searchParams['width'] = props.thumbnailWidth;
            }
            if (props.thumbnailHeight) {
                searchParams['height'] = props.thumbnailHeight;
            }
            thumbnail += new URLSearchParams(searchParams);
        }
        customPreview = thumbnail;
    }
    if (props.forVideoSeries === true) {
        url = props.fetchVideoSeriesVideo || '';
    }
    let activatePlaying = playing;
    let controlElements = controls;
    if (data.type === 'broadcast') {
        url = data.video;
        if (data.isLive) {
            config.file['forceHLS'] = true;
            activatePlaying = true;
            controlElements = true;
            customPreview = data.light;
        }
        else {
            config.file['forceVideo'] = true;
        }
    }
    const debouncedVideoStatisticsWatch = useCallback(debounce((userVideoId, id, playedSeconds) => {
        videoStatisticsWatch(userVideoId, id, playedSeconds);
    }, 500), []);
    const onEnded = () => {
        if (typeof props.redirectToOnFinished === 'undefined') {
            return () => { }; // eslint-disable-line
        }
        if (typeof props.redirectToOnFinished === 'function') {
            return props.redirectToOnFinished;
        }
        return (() => window.location.href = String(props.redirectToOnFinished));
    };
    const additionalConfig = {
        url,
        controls: controlElements,
        playing: activatePlaying,
        muted,
        volume: 1,
        light: customPreview,
        ...attributes,
        onEnded: onEnded(),
        chapters: data.chapters,
    };
    const onProgress = (stats) => {
        if (data.type === 'broadcast' && window.hasOwnProperty('plausible')) {
            // @ts-ignore
            window.plausible('aktive-pause-started');
        }
        if (userVideoId === '') {
            return;
        }
        if (stats.playedSeconds > 3 && ((Math.round(stats.playedSeconds) % 3 === 0 && stats.played < 0.98))) {
            debouncedVideoStatisticsWatch(userVideoId, id, stats.playedSeconds);
        }
    };
    const onFinish = () => {
        if (userVideoId === '') {
            return;
        }
        videoStatisticsFinish(userVideoId, id);
    };
    const onStarted = () => {
        videoStatisticsStart(id).then((v) => setUserVideoId(v));
    };
    // if (false) {
    //     return <VidStackPlayer url={url} type={'YOUTUBE'} videoTitle={'Test-Title'} />
    // }
    const responsivePlayer = <ResponsiveVideoPlayer onStart={props.onStart || onStarted} onProgress={props.onProgress || onProgress} onEnded={props.onFinish || onFinish} disableSeek={props.disableSeek} playerConfig={additionalConfig} sourceConfig={config}/>;
    const defaultPlayer = <DefaultReactPlayer playerConfig={additionalConfig} sourceConfig={config} onStart={props.onStart || onStarted} onProgress={props.onProgress || onProgress} onEnded={props.onFinish || onFinish} disableSeek={props.disableSeek} height={props.height} width={props.width}/>;
    return <div className={props.fullscreen ? '' : 'mx-4'} style={{ margin: props.withMargin ? '45px' : '0', width: props.width ?? '', height: props.height ?? '' }}>
        {responsive ? responsivePlayer : defaultPlayer}
    </div>;
};
VideoPlayer.displayName = 'VideoPlayer';
export default VideoPlayer;
